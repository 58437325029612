import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!

  return (_openBlock(), _createBlock(_component_Avatar, {
    imageFile: _ctx.url,
    useIcon: false,
    acronym: _ctx.acronym,
    size: "40"
  }, null, 8, ["imageFile", "acronym"]))
}