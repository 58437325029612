
import { defineComponent } from 'vue';
import Avatar from '@/components/avatars/Avatar.vue';
import { Actions } from '@/store/enums/UserEnums';
import { mapActions } from 'vuex';
import image from '@/utils/image';
import { acronym } from '@/utils/text';

export default defineComponent({
  components: { Avatar },
  props: {
    id: { default: null },
    radius: { default: false },
    width: { default: '50px' },
    height: { default: '50px' },
    name: { default: '' },
  },
  data() {
    return {
      url: '',
    };
  },
  computed: {
    acronym() {
      return acronym(this.name);
    },
  },
  methods: {
    ...mapActions({
      fetchAvatar: Actions.FETCH_AVATAR,
    }),
    async fetchTenantAvatar() {
      await this.fetchAvatar(this.id).then(async (logoRawImageData) => {
        const imageData = image.rawImageToDataUrl(logoRawImageData, 'jpg');
        this.url = imageData;
      });
    },
  },
});
