
import { defineComponent } from 'vue';
import Card from '@/views/new-design/components/Card.vue';
import DataTable from '@/components/datatables/KTDataTable.vue';
import { Actions, Getters, Mutations } from '@/store/enums/TenantEnums';
import { mapActions, mapGetters } from 'vuex';
import TenantAvatar from '@/views/new-design/pages/Tenants/TenantAvatar.vue';
import _ from 'lodash';
import timeUtil from '@/utils/time';
import useBreakpoints from 'vue-next-breakpoints';

export default defineComponent({
  components: { Card, DataTable, TenantAvatar },
  data: () => {
    return {
      table: {
        currentPage: 1,
        totalPages: 10,
        perPage: 15,
        total: 0,
        items: [],
        ordering: false,
      },
      debounceGetLists: Function(),
    };
  },
  computed: {
    ...mapGetters({
      tenants: Getters.GET_ALL_TENANTS,
    }),
    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },
    tableHeaders() {
      return [
        { name: 'Tenant Name', key: 'name', sortable: true },
        { name: 'Star Rating', key: 'rating', sortable: true },
        {
          name: 'Signed Up Date',
          key: 'signed_up_date',
          sortable: true,
          hidden: this.isMobile,
        },
        {
          name: 'Number of Reports',
          key: 'submitted_reference_count',
          sortable: true,
          hidden: this.isMobile,
        },
        {
          name: 'Referred By',
          key: 'referred',
          sortable: true,
        },
      ];
    },
  },
  watch: {
    'table.currentPage': {
      handler() {
        this.debounceGetLists();
      },
    },
  },
  methods: {
    ...mapActions({
      fetchTenants: Actions.GET_ALL_TENANTS,
    }),
    formatAgencyList() {
      return this.tenants.map((item) => {
        item.name = `${item.first_name} ${item.last_name}`;
        item.rating = parseFloat(item.average_rating);
        item.signed_up_date = item.signup_date;

        const referByName =
          item.referred_by_name !== null
            ? item.referred_by_name.business_name
            : null;

        const referById =
          item.referred_by !== null ? item.referred_by.business_name : null;

        item.referred = referById !== null ? referById : referByName;

        const referByNameBID =
          item.referred_by_name !== null ? item.referred_by_name.id : null;

        const referByIdBID =
          item.referred_by !== null ? item.referred_by.id : null;

        item.referred_id =
          referByIdBID !== null ? referByIdBID : referByNameBID;

        return item;
      });
    },
    async getTenants() {
      await this.fetchTenants({ returnAll: true }).then(() => {
        this.table.items = this.formatAgencyList();
        this.table.total = this.tenants.length;
      });
    },
    onPageChanged(page) {
      this.table.currentPage = page;
    },
    viewTenant(tenant: any, index: any) {
      console.log('viewTenant index', index);

      this.$router.push({
        name: 'view-tenant-details',
        params: { tenant_id: tenant.id },
      });
    },
    formatDate(date) {
      return timeUtil.dateFormat(date, 'aus');
    },
  },
  created() {
    this.debounceGetLists = _.debounce(() => {
      this.getTenants();
    }, 1000);
  },
  async mounted() {
    await this.getTenants();
  },
});
